import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import Button from '@/components/Button'
import Decoration1 from '@/components/SVGs/decoration1'
import { SOCIAL_LINK } from '@/utils/constants/common'
import { PAGES } from '@/utils/constants/pages'
import { ROBOTO_BOLD } from '@/utils/constants/themes'

const AboutSection = () => {
  const { t } = useTranslation('home')
  // const { themes } = useThemes()

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 overflow-x-hidden bg-gradient-to-b from-white/0 to-primary/10 py-16 dark:to-primary/[0.15] lg:flex-row lg:justify-center lg:gap-x-8 lg:py-32">
      <div
        className="relative flex w-full items-center justify-center lg:w-2/5"
        data-aos="fade-up"
      >
        <Image
          alt="Nur Muhamad Ash Shidiqi"
          src={`/images/about_bg.webp`}
          width={347}
          height={379}
          className="relative z-[2] !w-72 rounded-3xl lg:!w-96"
        />
        <Decoration1 className="absolute left-1/2 top-0 h-max w-96 -translate-x-20 -translate-y-8 xl:w-[640px]" />
      </div>
      <div className="z-[2] flex max-w-lg flex-col sm:max-w-xl lg:w-3/5 lg:max-w-2xl">
        <h2
          className={cn('h2 dark:text-primary', ROBOTO_BOLD.className)}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {t('home:about_title')}
        </h2>
        <p className={cn('mt-8 dark:font-light')} data-aos="fade-up">
          {t('home:about_desc')}
        </p>
        <div className="mt-12 flex items-center gap-x-4" data-aos="fade-up">
          <Button asLink linkProps={{ href: PAGES.about.path }} type="primary">
            {t('common:more_about_me')}
          </Button>
          <Button
            asLink
            linkProps={{
              href: SOCIAL_LINK.cv,
              target: '_blank',
              rel: 'nofollow',
            }}
            type="outline"
          >
            {t('common:download_cv')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
