import { type GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { BLOG, SKILLS_SET, TESTI, WORKS_EXP } from '@/utils/constants/data'
import {
  type AdvantagesDataType,
  type availableLanguages,
  type MainStaticProps,
  type ProjectsDataType,
} from '@/utils/constants/types'
import LandingPage from '@/views/LandingPage'
import { type LandingPageProps } from '@/views/LandingPage/types'

import * as Nexti18nConfig from '../../next-i18next.config'

type HomeProps = MainStaticProps<availableLanguages> & LandingPageProps

export const getStaticProps: GetStaticProps<HomeProps> = async ({ locale }) => {
  const i18n = await serverSideTranslations(
    locale as string,
    ['common', 'home'],
    {
      ...Nexti18nConfig,
      reloadOnPrerender: true,
    },
  )

  // * temporary data
  const projects = i18n._nextI18Next?.initialI18nStore[locale as string]?.home
    ?.projects as ProjectsDataType[]
  const advantages = i18n._nextI18Next?.initialI18nStore[locale as string]?.home
    ?.advantages as AdvantagesDataType[]

  return {
    props: {
      ...i18n,
      data: {
        projects,
        worksExp: WORKS_EXP,
        blog: BLOG,
        testimoni: TESTI,
        advantages,
        skills: SKILLS_SET,
      },
    },
    revalidate: 60,
  }
}

export default function Home({ data }: HomeProps) {
  return <LandingPage data={data} />
}
