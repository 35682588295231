import { useEffect, useState } from 'react'

import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import SocialIcons from '@/components/SocialIcons'
import { OPENSANS_BOLD } from '@/utils/constants/themes'

const HeroSection = () => {
  const { t } = useTranslation()

  const [textIndex, setTextIndex] = useState(0)

  const HERO_TEXTS: string[] = t('home:hero_texts', { returnObjects: true })

  useEffect(() => {
    setTimeout(() => {
      if (textIndex >= HERO_TEXTS.length - 1) {
        setTextIndex(0)
      } else {
        setTextIndex((v) => v + 1)
      }
    }, 2000)
  }, [textIndex])

  return (
    <div className="page-padding flex h-screen w-full flex-col items-center py-16 lg:py-32">
      <div className="flex flex-col items-center gap-y-8">
        <h1
          className={cn(
            'h1 w-full text-center text-lg',
            OPENSANS_BOLD.className,
          )}
        >
          {t('common:hi')}{' '}
          <span className="inline-block origin-bottom-right animate-wiggle">
            👋
          </span>{' '}
          <span className="mt-4 block text-primary-text sm:mt-0 sm:inline">
            {t('common:im_diqi')}
          </span>
        </h1>
        <p
          className={cn(
            'h3 relative h-8 w-full text-center sm:h-12',
            OPENSANS_BOLD.className,
          )}
        >
          {HERO_TEXTS.map((v, i) => {
            return (
              <span
                key={v}
                className={cn(
                  'my-transition absolute left-1/2 top-0 block w-max origin-center -translate-x-1/2',
                  {
                    'scale-100 delay-[2000]': textIndex === i,
                    'scale-0': textIndex !== i,
                  },
                )}
              >
                {v}
              </span>
            )
          })}
        </p>
        <SocialIcons
          show={{ ig: true, github: true, linkedin: true, yt: true }}
        />
      </div>
    </div>
  )
}

export default HeroSection
