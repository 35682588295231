import dynamic from 'next/dynamic'

import AboutSection from './partials/AboutSection'
// import AdvantageSection from './partials/AdvantageSection'
// import BlogSection from './partials/BlogSection'
import HeroSection from './partials/HeroSection'
// import ProjectSection from './partials/ProjectSection'
import SkillsSection from './partials/SkillsSection'
import { type LandingPageProps } from './types'
// import TestiSection from './partials/TestiSection'
// import WorksSection from './partials/WorksSection'

const WorksSection = dynamic(
  async () => await import('./partials/WorksSection'),
)
const ProjectSection = dynamic(
  async () => await import('./partials/ProjectSection'),
)
const AdvantageSection = dynamic(
  async () => await import('./partials/AdvantageSection'),
)
// const BlogSection = dynamic(async () => await import('./partials/BlogSection'))
const TestiSection = dynamic(
  async () => await import('./partials/TestiSection'),
)

// TODO: Before deploy app, make sure to remove dummy data (blog, etc)

const LandingPage = ({ data }: LandingPageProps) => {
  const { advantages, projects, worksExp, testimoni, skills } = data
  return (
    <div className="w-full pt-24">
      <HeroSection />

      <AboutSection />

      <SkillsSection data={skills} />

      <WorksSection data={worksExp} />

      <ProjectSection data={projects} />

      <AdvantageSection data={advantages} />

      {/* <BlogSection data={blog} /> */}

      <TestiSection data={testimoni} />
    </div>
  )
}

export default LandingPage
