import { type SVGProps } from 'react'

const Decoration1 = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="631"
      height="182"
      viewBox="0 0 631 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="220"
        width="411"
        height="133"
        fill="rgb(var(--primary-color))"
        fillOpacity="0.5"
      />
      <rect
        y="52"
        width="329"
        height="130"
        fill="rgb(var(--primary-color))"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default Decoration1
