import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { SKILL_ICONS } from '@/utils/constants/data'
import { OPENSANS_BOLD } from '@/utils/constants/themes'

import { type SkillsSectionProps } from '../types'

const POSITION = [
  { x: 47, y: 96, scale: 1, index: 3 },
  { x: 172, y: 26, scale: 1, index: 3 },
  { x: 287, y: 0, scale: 1, index: 3 },
  { x: 356, y: 82, scale: 2 / 3, index: 2 },
  { x: 252, y: 127, scale: 2 / 3, index: 2 },
  { x: 168, y: 134, scale: 0.5, index: 1 },
  { x: 306, y: 145, scale: 1, index: 3 },
  { x: 0, y: 218, scale: 2 / 3, index: 2 },
  { x: 141, y: 216, scale: 1, index: 3 },
  { x: 264, y: 252, scale: 0.5, index: 1 },
  { x: 414, y: 188, scale: 1, index: 3 },
  { x: 73, y: 328, scale: 1, index: 3 },
  { x: 214, y: 323, scale: 1, index: 3 },
  { x: 303, y: 264, scale: 2 / 3, index: 2 },
  { x: 385, y: 312, scale: 0.5, index: 1 },
]

const SkillsSection = ({ data }: SkillsSectionProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const [changer, setChanger] = useState(0)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const getIconPosition = (i: number) => {
    return POSITION[i % POSITION.length]
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (changer >= POSITION.length - 1) {
        setChanger(0)
      } else {
        setChanger((v) => v + 1)
      }
    }, 3000)

    if (isMouseOver) {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isMouseOver])

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 overflow-x-hidden bg-gradient-to-t from-white/0 to-primary/10 py-16 dark:to-primary/[0.15] lg:flex-row lg:justify-center lg:gap-x-8 lg:py-32 xl:gap-x-36">
      <div className="flex w-full xl:justify-end" data-aos="fade-right">
        <h2
          className={cn(
            'max-w-lg text-5xl uppercase leading-tight lg:text-7xl lg:leading-snug xl:w-[432px]',
            'dark:text-primary',
            OPENSANS_BOLD.className,
          )}
        >
          {t('home:skill_title')}
        </h2>
      </div>
      <div className="w-full" data-aos="fade-up">
        <div className="flex w-full flex-wrap gap-3 sm:hidden">
          {data.map(({ name, iconKey }) => {
            const Icon = SKILL_ICONS[iconKey]
            return (
              <div
                key={name}
                className={cn(
                  'my-transition shadow-primary-md flex h-24 w-24 scale-75 items-center justify-center rounded-2xl bg-white',
                  'hover:shadow-primary-md-dark dark:shadow-primary-md-dark dark:hover:shadow-primary-lg-dark hover:bg-light/25 dark:bg-dark',
                )}
              >
                <Icon />
              </div>
            )
          })}
        </div>

        {/* Tablet and desktop */}
        <div
          ref={wrapperRef}
          className="relative mx-auto hidden h-[450px] w-full max-w-lg flex-wrap gap-3 sm:flex xl:mx-0 "
          onMouseOver={() => {
            if (!isMouseOver) setIsMouseOver(true)
          }}
          onMouseLeave={() => {
            if (isMouseOver) setIsMouseOver(false)
          }}
        >
          {data.map(({ name, iconKey }, i) => {
            const Icon = SKILL_ICONS[iconKey]
            const { x, y, scale, index } = getIconPosition(Number(i) + changer)

            return (
              <div
                key={name}
                className={cn(
                  'my-transition shadow-primary-md absolute left-0 top-0 flex h-24 w-24 items-center justify-center rounded-2xl bg-white !duration-[3000ms]',
                  'hover:bg-light/25',
                  'shadow-primary-md-dark dark:bg-dark',
                  { 'dark:text-white': ['Express JS'].includes(name) },
                )}
                style={{
                  transform: `translate(${x}px, ${y}px) scale(${scale})`,
                  zIndex: index,
                }}
              >
                <Icon color="white" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SkillsSection
